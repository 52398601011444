<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" :model="filters">
				<span>
					<el-form-item>
						<el-input v-model="filters.keyword" placeholder="输入内容"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getList(0)" icon="el-icon-search">搜索</el-button>
					</el-form-item>
				</span>
				<el-form-item>
					<el-button type="primary" @click="handleAdd" icon="el-icon-plus">创建</el-button>
				</el-form-item>
			</el-form>
		</el-col>

		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" @selection-change="selsChange"
			style="width: 100%;">
			<el-table-column type="selection">
			</el-table-column>
			<el-table-column prop="id" label="ID" width="60">
			</el-table-column>
			<el-table-column prop="user_name" label="账号" sortable width="125">
			</el-table-column>
			<el-table-column prop="job_num" label="工号" sortable width="100">
			</el-table-column>
			<el-table-column prop="true_name" label="用户姓名" sortable width="125">
			</el-table-column>
			<el-table-column prop="role_name" label="角色组" sortable min-width="160">
			</el-table-column>
			<el-table-column prop="remark" label="备注">
			</el-table-column>
			<!-- <el-table-column prop="wx_status" label="绑微信" :formatter="formatWeixin" width="100">
			</el-table-column> -->
			<el-table-column prop="create_time" label="创建时间" sortable width="200">
			</el-table-column>
			<el-table-column label="账号状态" width="80" align="center">
				<template slot-scope="scope">
					<template v-if="scope.row.id != '1'">
						<span class="table-cell-status">
							<!-- <i class="red el-icon-error" v-if="scope.row.status=='0'" title="删除"></i> -->
							<i class="yellow el-icon-warning" v-if="scope.row.status == '2'" title="冻结"></i>
							<i class="green el-icon-success" v-else title="正常"></i>
						</span>
					</template>
				</template>
			</el-table-column>
			<el-table-column label="谷歌验证器" width="180" align="center">
				<template slot-scope="scope">
					<span class="table-cell-status">
						<i class="yellow el-icon-warning" v-if="scope.row.key == null || scope.row.key == ''"
							title="暂未绑定"></i>
						<i class="green el-icon-success" v-else title="正常绑定"></i>
					</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<template v-if="scope.row.id != '1'">
						<el-button type="success" plain size="small" @click="handleState(scope.$index, scope.row, '恢复')"
							v-if="scope.row.status == '2' || scope.row.status == '0'" icon="el-icon-refresh"
							title="恢复"></el-button>
						<el-button type="warning" plain size="small" @click="handleState(scope.$index, scope.row, '冻结')"
							icon="el-icon-warning" title="冻结" v-else></el-button>
						<el-button type="primary" plain size="small" @click="handleEdit(scope.$index, scope.row)"
							icon="el-icon-edit" title="编辑"></el-button>
						<!-- <el-button type="danger" plain size="small" @click="handleState(scope.$index, scope.row, '删除')" v-if="scope.row.status!='0'" icon="el-icon-delete" title="删除"></el-button> -->
					</template>
					<template v-if="scope.row.key == null || scope.row.key == ''">
						<el-button type="primary" plain size="small" @click="handlekey(scope.row.id)"
							icon="el-icon-s-tools" title="绑定"></el-button>
					</template>
				</template>
			</el-table-column>
		</el-table>

		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!-- <el-button type="danger" @click="handleState('', '', '批量删除')" :disabled="this.sels.length===0" icon="el-icon-delete">批量删除</el-button> -->
			<el-button type="warning" @click="handleState('', '', '批量冻结')" :disabled="this.sels.length === 0"
				icon="el-icon-warning">批量冻结</el-button>
			<el-button type="success" @click="handleState('', '', '批量恢复')" :disabled="this.sels.length === 0"
				icon="el-icon-refresh">批量恢复</el-button>
			<!--分页-->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
				:page-sizes="pagesizes" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-col>

	</section>
</template>

<script>

import { getAdminList, stateAdmin, getgoogleqr, getgooglekey } from '../../api/api';

export default {
	data() {
		return {
			filters: {
				keyword: ''
			},
			listData: [], //管理员列表数据
			//分页
			total: 0,
			page: 1,
			pagesize: 10,//每页条数
			pagesizes: [10, 20, 50, 100],//可选条数
			listLoading: false,
			sels: [],//列表选中列
		}
	},
	methods: {
		//分页
		handleSizeChange(val) {
			this.pagesize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getList();
		},
		//账号状态
		formatStatus: function (row, column) {
			return row.status == 1 ? '正常' : row.status == 2 ? '冻结' : row.status == 0 ? '删除' : '';
		},
		//绑微信
		formatWeixin: function (row, column) {
			return row.wx_status == 1 ? '是' : row.wx_status == 0 ? '否' : '';
		},
		//获取用户列表
		getList(val) {
			if (val == '0') this.page = 1
			let para = {
				page: this.page,
				keyword: this.filters.keyword,
				pagesize: this.pagesize	//分页条数
			};
			this.listLoading = true;
			getAdminList(para).then((res) => {
				this.listLoading = false;
				if (res.code == 1) {
					this.total = res.data.count;
					this.listData = res.data.data;
					this.listLoading = false;
				} else {
					this.$notify({
						title: '错误',
						message: res.msg,
						type: 'error'
					});
				}
			});
		},
		handlekey: function (id) {
			const promptForCode = (id, key) => {
				this.$prompt('请输入六位数动态码', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(({ value }) => {
					let page = {
						id: id,
						key: key,
						code: value
					}
					getgooglekey(page).then((res) => {
						if (res.code == 1) {
							this.$message({
								showClose: true,
								message: '绑定成功！',
								type: 'success'
							});
							this.getList();
						} else {
							this.$message({
								showClose: true,
								message: res.msg,
								type: 'error'
							});
							promptForCode(id, key);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消输入'
					});
				});
			};
			this.$confirm('是否确认绑定谷歌验证器？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let para = {
					id: id
				};
				getgoogleqr(para).then((res) => {
					this.listLoading = false;
					if (res.code == 1) {
						this.$alert('<img src ="' + res.data.qrcod_url + '">', '请扫描二维码绑定', {
							dangerouslyUseHTMLString: true,
							distinguishCancelAndClose: true,
							center: true,
							confirmButtonText: '确认绑定',
						}).then(() => {
							promptForCode(id, res.data.key);
						}).catch(() => {
							this.$message({
								type: 'info',
								message: '已取消绑定'
							});
						});
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消绑定'
				});
			});
		},
		//勾选
		selsChange: function (sels) {
			this.sels = sels;
		},
		//恢复、冻结、删除
		handleState: function (index, row, text) {
			this.$confirm('确认' + text + '该用户吗?', '提示', {
				type: 'warning'
			}).then(() => {
				this.listLoading = true;
				var astatus = function () {
					return text == '冻结' ? 2 : text == '恢复' ? 1 : text == '批量冻结' ? 2 : text == '批量恢复' ? 1 : '0';
				}
				//判断是否批量
				if (row) {
					var para = {
						id: row.id,
						status: astatus()
					};
				} else {
					var ids = this.sels.map(item => item.id).toString();
					var para = {
						id: ids,
						status: astatus()
					};
				}
				stateAdmin(para).then((res) => {
					this.listLoading = false;
					if (res.code == 1) {
						this.$message({
							message: text + '成功',
							type: 'success'
						});
						this.getList();
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						});
					}
				});
			}).catch(() => {

			});
		},
		//显示编辑界面
		handleEdit: function (index, row) {
			this.$router.push({ name: '编辑系统账号', query: { id: row.id } })
		},
		//显示新增界面
		handleAdd: function () {
			this.$router.push({ name: '编辑系统账号' })
		},
	},
	mounted() {
		//获取列表数据
		this.getList();
	}
}

</script>

<style lang="scss">
@import "../../styles/permission.scss";
</style>